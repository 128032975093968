let domain = process.env.REACT_APP_HOST;
// let domain = 'http://localhost:8080';

export const getMethod = async (route) => {
    try {
        let res = await fetch(`${domain}${route}`);
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};

export const deleteMethod = async (route,token) => {
    try {
        let res = await fetch(`${domain}${route}`,{
            method: "DELETE",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};

export const patchMethod = async (route,data,token) => {
    try {
        let res = await fetch(`${domain}${route}`,{
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};

export const postMethod = async (route,data,token) => {
    try {
        let res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};