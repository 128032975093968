import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { postMethod } from '../../Apis/apis'
import Swal from 'sweetalert2'

const LoginDiv = styled.div`
    width: 360px;
    padding: 8% 0 0;
    margin: auto;
    font-family: 'Comfortaa', cursive;
`
const FormDiv = styled.div`
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;

  input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Comfortaa', cursive;

    &:focus {
        background: #dbdbdb;
    }
  };

  button {
    font-family: 'Comfortaa', cursive;
    text-transform: uppercase;
    outline: 0;
    background: #4b6cb7;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;

    &:active {
        background: #395591;
    }
  };

  span {
    font-size: 75px;
    color: #4b6cb7;
  };

`
const BgDiv = styled.div`
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    height: 100vh;
`

const Login = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const navigate = useNavigate();
  const submitHandler = e => {
    e.preventDefault();
    let data = {
        email,password
    };
    (async () => {
        let res = await postMethod('/login',data);
        if(res){
          if(res.con){
            navigate("/admin/home");
              localStorage.setItem("BTC_auth",res.data.token);
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.message,
            })
          };
        }
    })();
  }
  return (
    <BgDiv>
        <LoginDiv>
            <FormDiv>
                    <form className="login-form" onSubmit={submitHandler}>
                        <span className="material-icons">Live</span>
                        <input type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} required/>
                        <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                        <button>login</button>
                    </form> 
            </FormDiv>
        </LoginDiv>
    </BgDiv>
  )
}

export default Login
