import React, { useState } from 'react'
import { FiEyeOff,FiEye } from "react-icons/fi";
import styled from 'styled-components'

const ToggleIcon = styled.span`
    position: absolute;
    right: 15px;
    top: 38px;
    margin-bottom: 0 !important;
    font-size: 20px !important;
    color: #7c7c7c !important;
`

const InputPass = styled.input`
    padding: 10px 40px 10px 10px;
`

const PasswordInput = ({name,forInput,placeHolder,passRef}) => {
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };
    return (
        <div className="mb-3 position-relative user-select-none">
            <label htmlFor={forInput} className="form-label" style={{fontSize: '14px', fontWeight: 'bolder'}}>{name}</label>
            <InputPass className="form-control user-select-none" type={!isVisible ? "password" : "text"} ref={passRef} placeholder={placeHolder} id={forInput} required />
            <ToggleIcon className="icon" onClick={toggle}>
                {isVisible ? <FiEye /> : <FiEyeOff />}
            </ToggleIcon>
        </div>
    )
}

export default PasswordInput
