import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {io} from "socket.io-client";
import {getMethod} from '../Apis/apis';
import moment from 'moment-timezone';

const ContainerDiv = styled.div`
   padding: 0 15px 15px 15px;
`
const Ul = styled.ul`

`
const Li = styled.li`
    background-color: #8FD9B6;
    padding: 20px 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 550;
    font-size: 17px;
    color: #2c2c2c;
    position: relative;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      display: block;
    }

    p {
      margin-bottom: 0;
      letter-spacing: .1px;
    }
`

const ResultFlex = styled.div`
    text-align: center;
`
const TimePosition = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 14px;
    background-color: #40122C;
    color: #FFF;
    padding: 7px 30px;
    letter-spacing: .5px;
    border-radius: 17px 17px 25px 25px
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`


const DailyResult = () => {
    const [result9,setResult9] = useState("--");
    const [result12,setResult12] = useState("--");
    const [result14,setResult14] = useState("--");
    const [result16,setResult16] = useState("--");
    const [result20,setResult20] = useState("--");
    const [set9,setSet9] = useState('- - - - - - -');
    const [set12,setSet12] = useState('- - - - - - -');
    const [set14,setSet14] = useState('- - - - - - -');
    const [set16,setSet16] = useState('- - - - - - -');
    const [set20,setSet20] = useState('- - - - - - -');


    let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
    useEffect(()=>{
       const fetch = async () => {
        let res = await getMethod(`/number/filter?date=${currentDate}`);
            if(res){
               if(res.data){
                if(res.data.length > 0){
                  res.data.map(obj => {
                      if(obj.time === "09:30 AM"){
                        setResult9(obj.result);
                        setSet9(obj.set);
                      };

                      if(obj.time === "12:00 PM"){
                        setResult12(obj.result);
                        setSet12(obj.set);
                      };
    
                      if(obj.time === "02:00 PM"){
                        setResult14(obj.result);
                        setSet14(obj.set);
                      };
    
                      if(obj.time === "04:30 PM"){
                        setResult16(obj.result);
                        setSet16(obj.set);
                      };

                      if(obj.time === "08:00 PM"){
                        setResult20(obj.result);
                        setSet20(obj.set);
                      };
                  });
                };
               }
            }
       };
       fetch();
    },[]);
    
    useEffect(
      () => {
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
        socket.connect();
        socket.on("result", res => {
            if(res){
              switch(res.time) {
                case "09:30 AM":
                  setResult9(res.result);
                  setSet9(res.set);
                  break;
                case "12:00 PM":
                  setResult12(res.result);
                  setSet12(res.set);
                  break;
                case "02:00 PM":
                  setResult14(res.result);
                  setSet14(res.set);
                  break;
                case "04:30 PM":
                  setResult16(res.result);
                  setSet16(res.set);
                  break;
                case "08:00 PM":
                  setResult20(res.result);
                  setSet20(res.set);
                  break;
                default:
                  return;
              };
            }
        });
        return () => {
          socket.disconnect();
        }
      },[]
    );
  return (
    <ContainerDiv>
       <Ul>
          <Li>
             <TimePosition>
                 09:30 AM
             </TimePosition>
             <ResultFlex>
                <span>Price</span>
                {
                  set9.includes('.')?
                  <span>
                    {
                      set9.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set9.split(".")[0][set9.split(".")[0].length - 1]}
                    </div>.
                    {
                      set9.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set9.split(".")[1][set9.split(".")[1].length - 1]}
                    </div>
                  </span>:
                  <span>{set9}</span>
                }
             </ResultFlex>
             <ResultFlex>
                <p>Result</p>
                <p>{result9}</p>
             </ResultFlex>
          </Li>
          <Li>
             <TimePosition>
                 12:00 PM
             </TimePosition>
             <ResultFlex>
                <span>Price</span>
                {
                  set12.includes('.')?
                  <span>
                    {
                      set12.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set12.split(".")[0][set12.split(".")[0].length - 1]}
                    </div>.
                    {
                      set12.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set12.split(".")[1][set12.split(".")[1].length - 1]}
                    </div>
                  </span>:
                  <span>{set12}</span>
                }
             </ResultFlex>
             <ResultFlex>
                <p>Result</p>
                <p>{result12}</p>
             </ResultFlex>
          </Li>
          <Li>
             <TimePosition>
                 02:00 PM
             </TimePosition>
             <ResultFlex>
                <span>Price</span>
                {
                  set14.includes('.')?
                  <span>
                    {
                      set14.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set14.split(".")[0][set14.split(".")[0].length - 1]}
                    </div>.
                    {
                      set14.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set14.split(".")[1][set14.split(".")[1].length - 1]}
                    </div>
                  </span>:
                  <span>{set14}</span>
                }
             </ResultFlex>
             <ResultFlex>
                <p>Result</p>
                <p>{result14}</p>
             </ResultFlex>
          </Li>
          <Li>
             <TimePosition>
                 04:30 PM
             </TimePosition>
             <ResultFlex>
                <span>Price</span>
                {
                  set16.includes('.')?
                  <span>
                    {
                      set16.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set16.split(".")[0][set16.split(".")[0].length - 1]}
                    </div>.
                    {
                      set16.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set16.split(".")[1][set16.split(".")[1].length - 1]}
                    </div>
                  </span>:
                  <span>{set16}</span>
                }
             </ResultFlex>
             <ResultFlex>
                <p>Result</p>
                <p>{result16}</p>
             </ResultFlex>
          </Li>
          <Li>
             <TimePosition>
                 08:00 PM
             </TimePosition>
             <ResultFlex>
                <span>Price</span>
                {
                  set20.includes('.')?
                  <span>
                    {
                      set20.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set20.split(".")[0][set20.split(".")[0].length - 1]}
                    </div>.
                    {
                      set20.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                      {set20.split(".")[1][set20.split(".")[1].length - 1]}
                    </div>
                  </span>:
                  <span>{set20}</span>
                }
             </ResultFlex>
             <ResultFlex>
                <p>Result</p>
                <p>{result20}</p>
             </ResultFlex>
          </Li>
       </Ul>
    </ContainerDiv>
  )
}

export default DailyResult
