import React from 'react';
import DailyResult from './DailyResult';
import Live from './Live';
import Header from './Header';
import styled from 'styled-components';

const Home = () => {
  const StickyDiv = styled.div`
      position: sticky;
      top: 0;
      z-index: 100;
  `
  return (
    <>
      <StickyDiv>
        <Header />
        <Live />
      </StickyDiv>
      <DailyResult />
    </>
  )
}

export default Home
