import moment from 'moment-timezone';
import React, { useEffect ,useState} from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getMethod, patchMethod } from '../../Apis/apis';
import DashboardLoading from '../../utils/DashboardLoading';

const H5 = styled.h5`
    margin-bottom: 0;
    color: #333;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    &:after {
        content: "";
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
`

const LabelSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 90px;
    height: 36px;
`

const InputSwitch = styled.input`
    display:none;
    &:checked + .slider {
        background-color: #2ab934;
    }
    &:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(55px);
    }
    &:checked + .slider:after {
        content:'ON';
    }
`

const SliderDiv = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;

    &:before {
        position: absolute;
        content: "";
        height: 34px;
        width: 32px;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: white;
        transition: 0.4s;
        border-radius: 6px;
    }
    &:after {
        content:'OFF';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
    }
`

const CloseContent = () => {
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [check, setCheck] = useState();
  const navigate = useNavigate();
  let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
  useEffect(()=>{
     (async () =>{
         let res = await getMethod('/close');
         if(res){
            setLoading(false);
            setData(res.data);
            if(res.data.length > 0){
                setCheck(res.data[0].status)
            }
         }
     })();
  },[date]);

  const closeHandler = async (id) => {
    setLoading(true)
    let token = localStorage.getItem("BTC_auth");
    let data = {
        status: !check
    };
    if(token){
        (async()=>{
            let res = await patchMethod(`/close/edit/${id}`,data, token);
            if(res){
                setLoading(false);
                if(res.message === "jwt malformed"){
                    navigate("/admin/login");
                };
                setCheck(res.data.status);
            };
        })();
    };
  };
  return (
    <>
       <div className='card h-100'>
            <div className='card-header' style={{
                    backgroundColor: "transparent",
                    borderBottom: 'none',
                    padding: '20px',
                    position: 'relative'}}>
                <H5>Close</H5>
            </div>
            <div className='card-body'>
                <table className="table h-100">
                        <thead className="table-dark">
                            <tr style={{
                                display:'table',
                                width:'100%',
                                tableLayout:'fixed'
                            }}>
                                <th>No.</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{
                            display: "block",
                            height:'90%',
                            overflow:'auto'
                        }}>
                            {
                                data.length > 0 && data.map((element,index) => 
                                <tr 
                                key={index}
                                style={{
                                            display:'table',
                                            width:'100%',
                                            tableLayout:'fixed'
                                    }}>

                                    <td style={{verticalAlign: 'middle'}}>{index + 1}.</td>  
                                    <td style={{verticalAlign: 'middle'}}>{date}</td>  
                                    <td style={{verticalAlign: 'middle'}}>
                                        <LabelSwitch>
                                            <InputSwitch type="checkbox" checked={check} onChange={()=>closeHandler(element._id)} id="togBtn" />
                                            <SliderDiv className='slider'></SliderDiv>
                                        </LabelSwitch>
                                    </td>  
                                </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
            </div>
        </div>
    {
        
        createPortal( loading && <DashboardLoading />, document.getElementById("portal"))
    }
    </>
  )
}

export default CloseContent
