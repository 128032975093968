import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import calendar from '../images/icons/calendar.png';

const HeaderDiv = styled.div`
   padding: 10px 30px;
   display:flex;
   justify-content: space-between;
   align-items: center;
   background-color: ${props => props.theme.primary};

   img {
     width: 60px;
   }
`

const CalendarBtn = styled.button`
     border: none;
     outline: none;
     background-color: transparent;
     cursor: pointer !important;
`
const Logo = styled.h5`
     margin-bottom: 0;
     font-size: 23px;
     color: #2c2c2c;
     cursor: pointer;
`

const Header = () => {

  return (
    <HeaderDiv>
        <Logo>BTC 2D</Logo>
        <div>
          <CalendarBtn>
            <Link to="result">
                {/* <FaRegCalendarAlt size="27px" color="#00A701" /> */}
                <img src={calendar} alt="mes" style={{width: "30px"}}/>
            </Link>
          </CalendarBtn>
        </div>
    </HeaderDiv>
  )
}

export default Header;