import {createSlice} from '@reduxjs/toolkit';

const LiveNumSlice = createSlice({
      name: "liveNumber",
      initialState: {
        value: "00",
      },
      reducers: {
         liveHandler: (state,action)=> {
            state.value = action.payload;
         }
      }
});

export const {liveHandler} = LiveNumSlice.actions;
export default LiveNumSlice.reducer;