import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({children}) => {
  let token = localStorage.getItem("BTC_auth");
  if(token){
     return children;
  }else{
     return <Navigate to="/admin/login" />
  }
}

export default RouteGuard;
