import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../Apis/apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import EmptyRecord from '../utils/EmptyRecord';
import moment from 'moment-timezone';

const Container = styled.div`
    max-width: 480px;
    min-height: 100vh;
    opacity: 1;
    background-color: #f5f5f5;
    background-attachment: fixed;
    margin: 0 auto;
    position: relative;
`
const Logo = styled.h5`
     margin-bottom: 0;
     font-size: 23px;
     color: #2c2c2c;
     cursor: pointer;
     margin-left: 10px;
`

const HeaderDiv = styled.div`
   padding: 10px 15px;
   display:flex;
   align-items: center;
   background-color: ${props => props.theme.primary};
   border-bottom: 1px solid #a8e6ff;
   position: sticky;
   top: 0;
   z-index: 100;

   img {
     width: 60px;;
     margin-left: 15px;
   }
   button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 30px;
      color: #FFF;
      position: absolute;
      right: 20px;

      div {
        position: relative;
      }

      span {
        font-size: 12px;
        background-color: red;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items:center;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: -5px;
      }
   }
`

const ContainerDiv = styled.div`
   padding: 0 15px 15px 15px;
`
const Ul = styled.ul`

`
const Li = styled.li`
    background-color: #8FD9B6;
    padding: 20px 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 550;
    font-size: 17px;
    color: #2c2c2c;
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      display: block;
    }

    p {
      margin-bottom: 0;
      letter-spacing: .1px;
    }
`

const ResultFlex = styled.div`
    text-align: center;
`
const TimePosition = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 14px;
    background-color: #40122C;
    color: #FFF;
    padding: 7px 30px;
    letter-spacing: .5px;
    border-radius: 17px 17px 25px 25px
`

const ResultTitle = styled.li`
    padding: 10px 0;
    font-size: 17px;
    color: rgb(94, 94, 94);
    text-align: center;
    font-weight: bolder;
`

const Result = () => {
  const [result,setResult] = useState([]);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(()=>{
      const fetch = async () => {
     let lastThree = [0,1,2,3,4,5,6];
        lastThree.map(async day => {
            let resDate = moment().subtract(day,'d').format('YYYY-MM-DD');
            let res = await getMethod(`/number/filter?date=${resDate}`);
            if(res){
                let obj = {
                    title: resDate,
                    data: res.data || []
                };
                setResult(prev => [...prev,obj].sort((a,b)=>{
                  return new Date(b.title)- new Date(a.title);
                }));
                setLoading(false);
            }else{
              setLoading(false)
            };
        });
    };
    fetch();
},[]);
  return (
    <Container>
      <HeaderDiv>
        <FaChevronLeft size="15px" color="#000" onClick={()=>navigate(-1)} cursor="pointer" />
        <Logo>BTC 2D</Logo>
      </HeaderDiv>
    <ContainerDiv>
      {
        result.length > 0 && 
        result.map((res,index) =>
            {
              if(res.data){
                return <Ul key={index}>
                          <ResultTitle>{res.title}  ({moment(res.title, "YYYY-MM-DD HH:mm:ss").format('dddd')})</ResultTitle>
                          {
                            res.data.length > 0?
                            res.data.map((el,index)=> 
                                  <Li key={index}>
                                    <TimePosition>
                                        {el.time}
                                    </TimePosition>
                                    <ResultFlex>
                                        <span>Price</span>
                                        <span>
                                            {
                                              el.set.split(".")[0].slice(0, -1)
                                            }
                                            <div style={{color: "#FF3366",display: 'inline-block'}}>
                                              {el.set.split(".")[0][el.set.split(".")[0].length - 1]}
                                            </div>.
                                            {
                                              el.set.split(".")[1].slice(0, -1)
                                            }
                                            <div style={{color: "#FF3366",display: 'inline-block'}}>
                                              {el.set.split(".")[1][el.set.split(".")[1].length - 1]}
                                            </div>
                                        </span>
                                    </ResultFlex>
                                    <ResultFlex>
                                        <p>Result</p>
                                        <p>{el.result}</p>
                                    </ResultFlex>
                                  </Li>
                            ):
                            <EmptyRecord message={`${res.title} အတွက် မှတ်တမ်းမရှိပါ`} />
                          }
                      </Ul>
              }else{
                return <Ul key={index}>
                          <ResultTitle>{res.title}</ResultTitle>
                          <EmptyRecord message={`${res.title} အတွက် မှတ်တမ်းမရှိပါ`} />
                      </Ul>
              }
            }
            
        )
      }
      </ContainerDiv>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </Container>
  )
}
export default Result;