import React from 'react'
import styled from 'styled-components'

const SidebarHeaderDiv = styled.div`
    padding: 24px; 
    text-transform: uppercase; 
    font-weight: bold; 
    font-size: 14px; 
    letter-spacing: 1px; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgb(43 43 43 / 10%);    
`

const Header = () => {
  return (
    <SidebarHeaderDiv>
      Admin Dashboard
    </SidebarHeaderDiv>
  )
}

export default Header
