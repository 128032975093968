import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {liveHandler} from '../store/liveNumSlice';
import {io} from "socket.io-client";
import { getMethod } from '../Apis/apis';
import {FiCheck} from 'react-icons/fi';

const ContainerDiv = styled.div`
   padding: 0 15px 15px 15px;
   background-color: ${props => props.theme.primary};
`

const LiveGroupContainer = styled.div`
   background-color: #8FD9B6;
   padding: 20px 0 10px 0;
   border-radius: 25px;
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`
const LiveNumber = styled.h1`
   text-align: center;
   user-select: none;
   color: #262626;
   font-size: 150px;
   font-weight: 500;
   text-shadow: 0 1px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 0;
   line-height: 150px;
`
const LiveNumberAnimation = styled.h1`
   text-align: center;
   user-select: none;
   color: #262626;
   font-size: 150px;
   font-weight: 500;
   text-shadow: 0 1px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 0;
   line-height: 150px;
   animation: ${TextBlink} 4s linear infinite;
`
const UpdateTime = styled.div`
   margin: 0;
   font-size: 14px;
   color: #262626;
   font-weight: 600;
   text-align: center;
   margin-bottom: 5px;
   user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
`
const UpdatedIcon = styled.span`
   background-color: #9EF0C9;
   display: block;
   width: 25px;
   height: 25px;
   text-align: center;
   line-height: 25px;
   border-radius: 50%;
   margin-right: 5px;
`
const LiveCard = styled.div`
   background-color: #8FD9B6;
   padding: 20px 40px;
   border-radius: 30px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 15px;
   font-weight: 550;
   font-size: 17px;
   color: #2c2c2c;

   p {
      margin-bottom: 0;
      letter-spacing: .1px;
   }
`

const LiveSetValue = styled.p`
    animation: ${TextBlink} 4s linear infinite;
`
let currentDate = moment().tz("Asia/Yangon").format("DD-MMMM-YYYY");
const Live = () => {
    const [hourTime,setHourTime] = useState("--");
    const [minuteTime,setMinuteTime] = useState("--");
    const [secondTime,setSecondTime] = useState("--");
    const [animate9,setAnimate9] = useState(false);
    const [animate13,setAnimate12] = useState(false);
    const [animate14,setAnimate14] = useState(false);
    const [animate16,setAnimate16] = useState(false);
    const [animate20,setAnimate20] = useState(false);
    const [check9,setCheck9] = useState(true);
    const [check12,setCheck12] = useState(true);
    const [check14,setCheck14] = useState(true);
    const [check16,setCheck16] = useState(true);
    const [check20,setCheck20] = useState(true);
    const [set,setSet] = useState("!!");
    const [result,setResult] = useState("!!");
    const mounted = useRef(false);
    const dispatch = useDispatch();
    let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
            socket.connect();
            socket.on("live", res => {
                let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
                if(timeconvert > "08:00:00" && timeconvert < "09:29:58"){
                    if(res){
                        setSet(res.set);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }
                
                if(timeconvert > "09:36:00" && timeconvert < "11:59:58"){
                    if(res){
                        setSet(res.set);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "12:06:00" && timeconvert < "13:59:58"){
                    if(res){
                        setSet(res.set);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "14:06:00" && timeconvert < "16:29:58"){
                    if(res){
                        setSet(res.set);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "16:36:00" && timeconvert < "19:59:58"){
                    if(res){
                        setSet(res.set);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }
            });
            return () => {
            socket.disconnect();
        };
    },[]);

    useEffect(()=>{
        mounted.current = true;
        const time = setInterval(updateTime , 1000);
        return () => {
            mounted.current = false;
            clearInterval(time);
        };
    },[]);

    const updateTime = () => {
        let currentTime = moment().tz("Asia/Yangon");
        let hour = (currentTime.hours()<10?'0':'') + currentTime.hours();
        let minute = (currentTime.minutes()<10?'0':'') + currentTime.minutes();
        let second = (currentTime.second()<10?'0':'') + currentTime.second();
        setHourTime(hour);
        setMinuteTime(minute);
        setSecondTime(second);

        let nine = "08:00:00";
        let endNine = "09:29:58";
        
        let twelve = "09:36:00";
        let endTwelve = "11:59:58";

        let two = "12:06:00";
        let endTwo = "13:59:58";
  
        let four = "14:06:00";
        let endFour = "16:29:58";

        let eight = "16:36:00";
        let endEight = "19:59:58";


        let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
    
        if(timeconvert > "09:29:59" && timeconvert < "09:35:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "09:30 AM"){
                                setSet(el.set);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "11:59:59" && timeconvert < "12:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "12:00 PM"){
                                setSet(el.set);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "13:59:59" && timeconvert < "14:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "02:00 PM"){
                                setSet(el.set);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "16:29:59" && timeconvert < "16:35:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "04:30 PM"){
                                setSet(el.set);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "19:59:59" && timeconvert < "23:59:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "08:00 PM"){
                                setSet(el.set);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if (timeconvert > nine && timeconvert < endNine){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate9(true);
                            setCheck9(true);
                        }else{
                            setAnimate9(false);
                            setCheck9(false);
                        }
                   }
                }
            })();
        }else{
            setAnimate9(false);
            setCheck9(false);
        };

        if (timeconvert > twelve && timeconvert < endTwelve){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate12(true);
                            setCheck12(true);
                        }else{
                            setAnimate12(false);
                            setCheck12(false);
                        };
                   };
                };
            })();
        }else{
            setAnimate12(false);
            setCheck12(false);
        };

        if (timeconvert > two && timeconvert < endTwo){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate14(true);
                            setCheck14(true);
                        }else{
                            setAnimate14(false);
                            setCheck14(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate14(false);
            setCheck14(false)
        };

        if (timeconvert > four && timeconvert < endFour){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate16(true);
                            setCheck16(true);
                        }else{
                            setAnimate16(false);
                            setCheck16(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate16(false);
            setCheck16(false)
        };

        if (timeconvert > eight && timeconvert < endEight){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate20(true);
                            setCheck20(true);
                        }else{
                            setAnimate20(false);
                            setCheck20(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate20(false);
            setCheck20(false)
        };
    };
  return (
    <ContainerDiv>
        <LiveGroupContainer>
            {
                animate9 || animate13 || animate14 || animate16 || animate20 ? <LiveNumberAnimation>{result}</LiveNumberAnimation> : <LiveNumber>{result}</LiveNumber>
            }
            <UpdateTime>
                <div style={{marginRight: '5px', display: 'flex',alignItems: 'center'}}> 
                {(check9 || check12 || check14 || check16 || check20 ) ? null : 
                    <UpdatedIcon><FiCheck color='#827B00' size="16px" /></UpdatedIcon>
                }
                    Updated at :</div>
                <div>{currentDate} {hourTime}:{minuteTime}:{secondTime}</div>
            </UpdateTime>
        </LiveGroupContainer>
        <LiveCard>
            <span>BTC Price</span>
            {animate9 || animate13 || animate14 || animate16 || animate20? <LiveSetValue>{set.includes('.')?
                <span>
                    {
                    set.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                    {set.split(".")[0][set.split(".")[0].length - 1]}
                    </div>.
                    {
                    set.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                    {set.split(".")[1][set.split(".")[1].length - 1]}
                    </div>
                </span>: '!!'
            }</LiveSetValue> :<p>{set.includes('.')?
                <span>
                    {
                    set.split(".")[0].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                    {set.split(".")[0][set.split(".")[0].length - 1]}
                    </div>.
                    {
                    set.split(".")[1].slice(0, -1)
                    }
                    <div style={{color: "#FF3366",display: 'inline-block'}}>
                    {set.split(".")[1][set.split(".")[1].length - 1]}
                    </div>
                </span>: '!!'
            }</p>}
        </LiveCard>
    </ContainerDiv>
  )
}

export default Live
