import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
   &.cube .block > *{
        fill:${props => props.theme.primary};
        // fill: rgb(255, 136, 0);
        animation: anim-cube-grid 1.5s ease-in-out infinite;
    }
   
   &.cube .block-1{
        transform-origin:16px 16px;
        animation-delay: -0.2s;
    }
   &.cube .block-2{
        transform-origin:48px 16px;
        animation-delay: -0.1s;
    }
    &.cube .block-3{
        transform-origin:80px 16px;
        animation-delay: -0.0s;
    }
    &.cube .block-4{
        transform-origin:16px 48px;
        animation-delay: -0.3s;
    }
    &.cube .block-5{
        transform-origin:48px 48px;
        animation-delay: -0.2s;
    }
    &.cube .block-6{
        transform-origin:80px 48px;
        animation-delay: -0.1s;
    }
    &.cube .block-7{
        transform-origin:16px 80px;
        animation-delay: -0.4s;
    }
    &.cube .block-8{
        transform-origin:48px 80px;
        animation-delay: -0.3s;
    }
    &.cube .block-9{
        transform-origin:80px 80px;
        animation-delay: -0.2s;
    }
    @keyframes anim-cube-grid{
        0%   {transform:scale(1.0);}
        25%  {transform:scale(0.0);}
        50%  {transform:scale(1.0);}
        100% {transform:scale(1.0);}
    }
`

const LoadingContainerDiv = styled.div`
    width: 100%;
    position:fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DashboardLoading = () => {
  return (
    <LoadingContainerDiv>
        <Svg className="cube" width="64px" style={{borderRadius: "10px"}} height="64px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
            <g className="block">
                <rect className="block-1" x=" 0" y=" 0" width="33" height="33"></rect>
                <rect className="block-2" x="32" y=" 0" width="33" height="33"></rect>
                <rect className="block-3" x="64" y=" 0" width="33" height="33"></rect>
                <rect className="block-4" x=" 0" y="32" width="33" height="33"></rect>
                <rect className="block-5" x="32" y="32" width="33" height="33"></rect>
                <rect className="block-6" x="64" y="32" width="33" height="33"></rect>
                <rect className="block-7" x=" 0" y="64" width="33" height="33"></rect>
                <rect className="block-8" x="32" y="64" width="33" height="33"></rect>
                <rect className="block-9" x="64" y="64" width="33" height="33"></rect>
            </g>
        </Svg>
    </LoadingContainerDiv>
  )
}

export default DashboardLoading;